export const environment = {
    production: false,
    version: "24.08.26.dev",

    mail_support: 'mariela.mamani@makingconnexion.com',
    
    app_urls: {
       //  source:'http://localhost:8000',
     source: 'https://adp-api.pruebasgt.com', // 'http://localhost:8000',
       clipping: 'https://adp-clipping.pruebasgt.com' // 'http://localhost:4201'
    },

    pucp: false,

    images: {
        DEFAULT_AVATAR_PATH: './assets/images/default-avatar.png',
        DEFAULT_NO_IMAGE: './assets/images/noimage.png',
        LOGOTYPE: './assets/images/logotype.png'
    },
    
    localStorageKeys: {
        AUTH: 'AUTH_STG',
        TOKEN: 'TOKEN_STG'
    }
};
